<template>
    <div class="animated fadeIn">
        <b-card>
            <b-button class="pull-right mr-1" style="background-color:#c8ced3;color:black;" @click="$router.back()">취소
            </b-button>
            <b-button class="pull-right mr-1" variant="dark" @click="submit('temp')">임시저장</b-button>
            <b-button class="pull-right mr-1" variant="success" @click="submit">저장</b-button>
        </b-card>
        <b-card
                :header-html="popupCardHeaderHtml"
        >
            <b-form>
                <b-form-group label="제목" :label-cols="1">
                    <b-form-input v-model="title" type="text" placeholder="제목 입력"></b-form-input>
                </b-form-group>
                <b-form-group label="공지기간" :label-cols="1">
                    <b-form inline>
                        <date-input v-model="periodStartDate"></date-input>
                        <label class="mx-sm-2"></label>
                        <b-form-input class="w-50px" :plain="true" v-model="periodStartHour"></b-form-input>
                        <label class="mx-sm-2">시</label>
                        <b-form-input class="w-50px" :plain="true" v-model="periodStartMinute"></b-form-input>
                        <label class="mx-sm-2">분 ~ </label>
                        <date-input v-model="periodEndDate"></date-input>
                        <label class="mx-sm-2"></label>
                        <b-form-input class="w-50px" :plain="true" v-model="periodEndHour"></b-form-input>
                        <label class="mx-sm-2">시</label>
                        <b-form-input class="w-50px" :plain="true" v-model="periodEndMinute"></b-form-input>
                        <label class="mx-sm-2">분</label>
                    </b-form>
                </b-form-group>
                <b-form-group label="상태" :label-cols="1">
                    <b-form-select class="w-150px"
                                   :plain="true"
                                   :options="statusOptions"
                                   :value="null"
                                   v-model="selectedStatus">
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>
                                노출 상태 선택
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="링크" :label-cols="1">
                    <b-form-input v-model="link" type="text" placeholder="링크 입력"></b-form-input>
                </b-form-group>
                <b-form-group label="플랫폼 선택" :label-cols="1">
                    <b-form-select class="w-125px mr-2"
                                   :plain="true"
                                   :options="platformOptions"
                                   value=""
                                   v-model="platform"
                    >
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>
                                플랫폼 선택
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group label="팝업 유형" :label-cols="1" v-if="platform === 'mobile'">
                    <b-form-select class="w-150px mr-2"
                                   :plain="true"
                                   :options="[{
                                     text: '공지팝업',
                                     value: 'normal'
                                   }, {
                                     text: '전면팝업',
                                     value: 'full'
                                   }]"
                                   v-model="size"
                                   @change="checkMobileSize()"
                    >
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>
                                팝업 유형 선택
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group v-if="size==='normal'" label="노출설정" :label-cols="1">
                    <b-form-select class="w-200px "
                                   :plain="true"
                                   :options="displayOptions"
                                   value=""
                                   v-model="displayCondition"
                                   placeholer="노출위치"
                                   @change="selectDisplayCondition"
                    >
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>
                                노출위치 선택
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="mt-2">
                        <b-btn class="mr-2" variant="primary" v-for="(condition, index) of selectedDisplayConditions"
                               :key="index">
                            {{getDisplayText(condition)}}
                            <span class="fa fa-times-circle" @click="removeDisplayCondition(index)"
                                  style="margin-left: 10px;"></span>
                        </b-btn>
                    </div>
                </b-form-group>

            </b-form>
        </b-card>
        <b-card
                :header-html="displayCardHeaderHtml"
        >
            <b-form>
                <template v-for="(set, index) of displaySet">
                    <b-form :key="index">
                        <b-form-group label="PC/Mobile" :label-cols="1">
                            <b-form inline class="mb-2">

                                <b-form-select class="w-125px mr-2"
                                               :plain="true"
                                               :options="genderOptions"
                                               value=""
                                               v-model="set.gender"
                                               @change="checkDuplicateSet(index)"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>
                                            성별 선택
                                        </b-form-select-option>
                                    </template>
                                </b-form-select>
                                <!--<b-form-select v-if="set.platform==='mobile'" class="w-125px mr-2"
                                               :plain="true"
                                               :options="[{
                                                         text: '일반',
                                                         value: 'normal'
                                                       }, {
                                                         text: '전면팝업',
                                                         value: 'full'
                                                       }]"
                                               v-model="set.size"
                                               @change="checkMobileSize(index)"
                                >
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>
                                            사이즈 선택
                                        </b-form-select-option>
                                    </template>
                                </b-form-select>-->
                                <b-btn variant="danger" @click="removeDisplaySet(index)">노출 삭제</b-btn>
                            </b-form>
                            <b-form-file v-model="set.file" @change="change($event, index)" ref="file-input" class="mb-2"></b-form-file>
                            <img v-show="set.img_link" :src="set.img_link" width="300" alt="">
                        </b-form-group>
                    </b-form>
                </template>
                <!--<b-form-group label="" :label-cols="1">
                    <b-btn variant="dark"
                           @click="displaySet.push({
                                platform: null,
                                gender: null,
                                file: null,
                                size: null
                           })"
                    >+ 추가
                    </b-btn>
                </b-form-group>
                <b-form-group label="노출설정" :label-cols="1">
                    <b-form-select class="w-200px "
                                   :plain="true"
                                   :options="displayOptions"
                                   value=""
                                   v-model="displayCondition"
                                   placeholer="노출위치"
                                   @change="selectDisplayCondition"
                    >
                        <template v-slot:first>
                            <b-form-select-option :value="null" disabled>
                                노출위치 선택
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="mt-2">
                        <b-btn class="mr-2" variant="primary" v-for="(condition, index) of selectedDisplayConditions"
                               :key="index">
                            {{getDisplayText(condition)}}
                            <span class="fa fa-times-circle" @click="removeDisplayCondition(index)"
                                  style="margin-left: 10px;"></span>
                        </b-btn>
                    </div>
                </b-form-group>-->
            </b-form>
        </b-card>
    </div>
</template>

<script>
  import dateInput from '@/views/modules/DateInput.vue'
  import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
  import MaskedInput from 'vue-text-mask'
  import * as axios from 'axios';
  import {HOST} from '@/shared/api';
  import moment from 'moment-timezone';

  const today = new Date();

  export default {
    name: "PopupDetail",
    components: {
      dateInput,
      MaskedInput
    },
    data() {
      return {
        popupCardHeaderHtml: `<strong class="mr-3">팝업 정보</strong>`,
        displayCardHeaderHtml: `
            <strong class="mr-3">노출 정보</strong>
        `,
        statusOptions: [{
          text: '진행',
          value: 2
        }, {
          text: '대기',
          value: 1
        }, {
          text: '종료',
          value: 0
        }],
        displayOptions: [{
          text: '메인',
          value: 'main'
        }, {
          text: '상품 상세화면',
          value: 'goods_view'
        }, {
          text: '상품 목록',
          value: 'goods_list'
        }, {
          text: '장바구니',
          value: 'cart'
        }, {
          text: '고객센터',
          value: 'cs'
        }],

        platformOptions: [{
          text: 'PC',
          value: 'pc'
        }, {
          text: 'Mobile',
          value: 'mobile'
        }],
        genderOptions: [{
          text: '공통',
          value: 'both'
        }, {
          text: 'Women',
          value: 'women'
        }, {
          text: 'Men',
          value: 'men'
        }],

        displaySet: [{
          // platform: null,
          gender: null,
          file: null,
          size: 'normal'
        }],

        title: '',
        periodStartDate: '',
        periodStartHour: '',
        periodStartMinute: '',
        periodEndDate: '',
        periodEndHour: '',
        periodEndMinute: '',
        selectedStatus: null,
        displayCondition: null,
        platform: 'null',
        selectedDisplayConditions: [],
        link: '',
        size: null
      }
    },
    async created() {
      if (this.$route.query.popup_idx) {
        await this.fetch();
      }
    },
    methods: {
      async upload(file) {
        const formData = new FormData();

        // formData.append('img', file);

        // const response = await axios.post('https://dev.balaan.co.kr/images/upload', formData);
        // const response = await this.$api.postForm('/godo/events/upload', formData);

        formData.append('file', file);
        formData.append('bucket', this.S3_CDN);
        formData.append('path', 'popup/images/');
        formData.append('thumbs', '600,800');
        const response = await axios.post(this.$api.getHost() + '/goods/forwardSingleImage', formData);
        // const response = await axios.post('https://image2s3.balaan.io/upload/single', formData, {
        //   headers: {
        //     Authorization: 'Basic YmFsYWFuOnFrZmZrc2RsYWx3bDIwISE='
        //   }
        // });

        if (!response || !response.data || !response.data.org) {
          return alert('이미지 업로드 도중 에러가 발생 하였습니다.')
        }

        return response.data.org.url;
      },
      async change(event) {
        let file = (event.dataTransfer || event.target).files[0];
        const response = await this.upload(file);
        if (!response) {
          return;
        }

        this.displaySet[0].img_link = response;
        this.displaySet[0].file = null;
      },
      async removeDisplaySet(index) {
        const bpsIdx = this.displaySet[index].bps_idx;
        if (bpsIdx) {
          const response = await this.$api.getJson(`/godo/popup/${this.$route.query.popup_idx}/set/${bpsIdx}/delete`);
          if (!response || response.error) {
            return alert('노출정보를 삭제하는 도중에 에러가 발생 하였습니다.');
          }
        }

        this.displaySet.splice(index, 1);
      },
      async removeDisplayCondition(index) {
        this.selectedDisplayConditions.splice(index, 1);
      },
      async fetch() {
        const response = await this.$api.getJson(`/godo/popup/${this.$route.query.popup_idx}`);
        if (!response || response.error) {
          return alert('데이터를 불러오는 도중에 에러가 발생하였습니다.');
        }

        this.displaySet = response.data.displaySet;
        this.selectedDisplayConditions = response.data.display;

        this.link = response.data.link;
        this.title = response.data.title;
        this.selectedStatus = response.data.status;

        const periodStart = moment(response.data.period_start);
        this.periodStartDate = periodStart.format('YYYY-MM-DD')
        this.periodStartHour = periodStart.format('HH')
        this.periodStartMinute = periodStart.format('mm')

        const periodEnd = moment(response.data.period_end);
        this.periodEndDate = periodEnd.format('YYYY-MM-DD')
        this.periodEndHour = periodEnd.format('HH')
        this.periodEndMinute = periodEnd.format('mm')

        this.platform = response.data.platform;
        this.size = response.data.size;
      },
      autoCorrectedDatePipe() {
        return createAutoCorrectedDatePipe('yyyy-mm-dd')
      },
      selectDisplayCondition() {
        if (~this.selectedDisplayConditions.indexOf(this.displayCondition)) {
          return alert('이미 선택된 노출 위치입니다.');
        }

        this.selectedDisplayConditions.push(this.displayCondition);
        this.displayCondition = null;
      },
      getDisplayText(value) {
        let text = '';
        for (const option of this.displayOptions) {
          if (option.value === value) {
            text = option.text;
            break;
          }
        }

        return text;
      },
      checkDuplicateSet(index) {
        const currSet = this.displaySet[index];
        let flag = false;
        for (let i = 0; i < this.displaySet.length; i++) {
          if (index === i) continue;

          const set = this.displaySet[i];
          if (set.platform === currSet.platform && set.gender === currSet.gender) {
            alert('중복 선택은 불가능 합니다')
            flag = true;
            break;
          }
        }

        if (flag) {
          currSet.platform = null;
          currSet.gender = null;
        }
      },
      checkMobileSize() {
        /*if (!this.displaySet.length) return;
        const currSet = this.displaySet[index];

        let sizeGroup = {};
        for (const set of this.displaySet) {
          if (set.platform === 'mobile') {
            if (set.size && !sizeGroup[set.size]) {
              sizeGroup[set.size] = set;
            }
          }
        }

        if (Object.keys(sizeGroup).length > 1) {
          alert('동시에 두가지 사이즈를 선택할 수 없습니다.');
          currSet.size = '';
        }*/
      },
      async submit() {
        const dataSet = {};

        dataSet.title = this.title;

        dataSet.periodStart = `${this.periodStartDate} ${this.periodStartHour}:${this.periodStartMinute}:00`;
        dataSet.periodEnd = `${this.periodEndDate} ${this.periodEndHour}:${this.periodEndMinute}:00`;
        dataSet.status = this.selectedStatus;
        dataSet.link = this.link;
        dataSet.display = this.selectedDisplayConditions;
        dataSet.size = this.size;
        dataSet.platform = this.platform;


        let popupIdx;
        if (this.$route.query.popup_idx) {
          let response = await this.$api.postJson(`/godo/popup/${this.$route.query.popup_idx}/update`, dataSet);
          if (!response) {
            return
          }

          popupIdx = this.$route.query.popup_idx;
        } else {
          let response = await this.$api.postJson(`/godo/popup`, dataSet);
          if (!response) {
            return
          }

          popupIdx = response.popupIdx;
        }

        for (const set of this.displaySet) {
          // const formData = new FormData();

          // formData.append('platform', set.platform);
          // formData.append('gender', set.gender);
          // formData.append('img', set.file);
          // formData.append('img_link', set.img_link);
          // formData.append('size', 'full');
          const data = {
            gender: set.gender,
            img_link: set.img_link,
            size: 'full'
          }

          if (set.bps_idx) {
            const response = await this.$api.postJson(`/godo/popup/${popupIdx}/set/${set.bps_idx}/update`, data);
            if (!response) {
              return
            }
          } else {
            const response = await this.$api.postJson(`/godo/popup/${popupIdx}/set`, data);
            if (!response) {
              return
            }
          }
        }

        alert('성공적으로 저장되었습니다.');
        this.$router.replace('popup');
      }
    }
  }
</script>

<style scoped>
</style>

